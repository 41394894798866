import { Popover, Button, Title, Group, Flex } from '@mantine/core';
import { useState } from 'react';

import { ArrowDropdown } from '../../icons';
import { SectionDropdownItems } from './SectionDropdown';

import classes from './DropdownMenus.module.css';

interface Props {
  sectionTitle: string
  sectionUid: string
  isSubsection: boolean
  canEdit: boolean
  isOnlyTopLevelSection: boolean
  handleOpenAddRequirement: (sectionUid: string) => void
  handleOpenDelete: (sectionUid: string) => void
  handleOpenRename: (sectionUid: string) => void
  handleOpenAddSubsection: (parentSectionUid: string) => void
}

const SectionTitleDropdown: React.FC<Props> = (props: Props) => {
  const [opened, setOpened] = useState(false);
  const {
    sectionTitle,
    sectionUid,
    canEdit,
    handleOpenAddRequirement,
    handleOpenDelete,
    handleOpenRename,
    handleOpenAddSubsection,
    isSubsection,
    isOnlyTopLevelSection
  } = props;

  if (!canEdit) {
    return <Title ml={16} order={4}> {sectionTitle} </Title>;
  }

  return (
    <Button h={36} p='0px 8px' variant='subtle' onClick={() => { setOpened((o) => !o); } }>
      <Group gap='xs'>
        <Title order={5} fw={600}> {sectionTitle} </Title>
        <Popover
          position='bottom-start'
          classNames={{
            dropdown: classes.popoverDropdown
          }}
          opened={opened}
          onChange={() => { setOpened((o) => !o); }}
        >
          <Popover.Target>
            <Flex> <ArrowDropdown color='var(--mantine-color-darkPurple-9)'/> </Flex>
          </Popover.Target>
          <Popover.Dropdown>
            <SectionDropdownItems
              isCurrentPage={true}
              isOnlyTopLevelSection={isOnlyTopLevelSection}
              openAddRequirement={() => { handleOpenAddRequirement(sectionUid); setOpened(false); }}
              openDelete={() => { handleOpenDelete(sectionUid); setOpened(false); }}
              openRename={() => { handleOpenRename(sectionUid); setOpened(false); }}
              openAddSubsection={() => { handleOpenAddSubsection(sectionUid); setOpened(false); }}
              isSubsection={isSubsection}
            />
          </Popover.Dropdown>
        </Popover>
      </Group>
    </Button>
  );
};

export default SectionTitleDropdown;
