import { Box, Modal, Button, LoadingOverlay, ScrollArea } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { useMemo, useState } from 'react';

import { type Row } from '@tanstack/react-table';

import { useParams } from 'react-router-dom';

import { Pagination, Table } from '../../components';

import useCurrentUsersTableData from '../../hooks/useCurrentUsersTableData';
import { type CurrentUserRow } from '../../types/tableTypes';

import InviteUsers from '../../components/Forms/InviteUsers/InviteUsers';
import RemoveUser from '../../components/Modals/RemoveUser/RemoveUser';
import { getUserFullName } from '../../utils/stringUtils';
import { defaultModalProps } from '../../utils/mantineUtils';
import { EditUserRole } from '../../components/Modals';

const CurrentUsers: React.FC = () => {
  const [inviteUserOpened, inviteUserHandlers] = useDisclosure(false);
  const [removeUserOpened, removeUserHandlers] = useDisclosure(false);
  const [editRoleOpened, editRoleHandlers] = useDisclosure(false);

  const [currentUser, setCurrentUser] = useState<CurrentUserRow>();
  const { organization } = useParams();

  const handleRemoveOpen = (row: Row<CurrentUserRow>) => {
    setCurrentUser(row.original);
    removeUserHandlers.open();
  };

  const handleEditRoleOpen = (row: Row<CurrentUserRow>) => {
    setCurrentUser(row.original);
    editRoleHandlers.open();
  };

  const currentUserName = useMemo(() => getUserFullName(currentUser), [currentUser]);
  const { table, isLoading } = useCurrentUsersTableData(organization ?? '', handleRemoveOpen, handleEditRoleOpen);

  return (
    <Box ta={'end'} maw={850}>
      <Modal opened={editRoleOpened && currentUser !== undefined} {...defaultModalProps}>
        {currentUser !== undefined && <EditUserRole close={editRoleHandlers.close} user={currentUser}/> }
      </Modal>
      <Modal opened={removeUserOpened} {...defaultModalProps}>
        <RemoveUser close={removeUserHandlers.close} name={currentUserName} userUid={currentUser?.uid}/>
      </Modal>
      <Modal opened={inviteUserOpened} {...defaultModalProps}>
        <InviteUsers close={inviteUserHandlers.close}/>
      </Modal>
      <LoadingOverlay visible={isLoading} />
      <Button onClick={inviteUserHandlers.open}> Invite Users</Button>
      <ScrollArea mb={10} style={{ height: 'calc(100vh - 350px)' }}>
        <Table tableStyle='simple' table={table}/>
      </ScrollArea>
      <Pagination table={table}/>
    </Box>
  );
};

export default CurrentUsers;
