import { useParams } from 'react-router-dom';
import {
  Box,
  Title,
  LoadingOverlay,
  Group,
  Button,
  ScrollArea,
  Modal,
  Accordion
} from '@mantine/core';

import { useCallback, useMemo } from 'react';

import { useDisclosure } from '@mantine/hooks';

import useEnsisQuery from '../../hooks/useEnsisQuery';
import { CenteredLoader, SelectProposalResources, UploadResources } from '../../components';
import { hasPermissionsForObject } from '../../utils/apiUtils';
import useEnsisMutation from '../../hooks/useEnsisMutation';
import { DeleteProposal } from '../../components/Modals';
import { defaultModalProps } from '../../utils/mantineUtils';
import OpportunityFilesSection from './OpportunityFilesSection';
import { ArrowRight } from '../../icons';
import classes from './ManageProposal.module.css';
import { AdditionalProposalInfo } from './AdditionalProposalInfo';
import DetailsSection from './DetailsSection';
import ProposalFactsSection from './ProposalFactsSection';

const ManageProposalPage: React.FC = () => {
  const { proposalUid } = useParams();
  const { data, isLoading } = useEnsisQuery(`/app/proposals/${proposalUid}/data`);
  const [opened, { open, close }] = useDisclosure();
  const [uploadOpened, uploadHandlers] = useDisclosure();
  const hasDeletePermission = hasPermissionsForObject(data, 'delete');
  const hasViewPermission = hasPermissionsForObject(data, 'view');
  const hasChangePermission = hasPermissionsForObject(data, 'change');

  const exportProposalMutation = useEnsisMutation(
    '/app/proposal-exports',
    {
      contentType: 'application/json',
      requestType: 'post',
      showSuccessMessage: false,
      onSuccess: (data) => {
        if (data?.url != null) {
          window.open(data?.url, '_blank', 'noreferrer');
        }
      }
    }
  );

  const handleExportProposal = useCallback(() => {
    exportProposalMutation.mutate({ proposal_uid: proposalUid ?? '' });
  }, [exportProposalMutation.mutate]);

  const opportunityUid = data?.opportunity.uid ?? '';
  const proposalReferences = data?.references?.map((reference) => reference.uid) ?? [];
  const accordionData = useMemo(() => {
    const defaultAccordianItems = [
      {
        label: 'Opportunity Files',
        key: 'opportunity-files',
        component: <OpportunityFilesSection opportunityUid={opportunityUid}/>
      },
      {
        label: 'Resources',
        key: 'resources',
        component: <SelectProposalResources referenceUids={proposalReferences} showButtons/>
      },
      {
        label: 'Additional Proposal Info',
        key: 'additional-proposal-info',
        component: <AdditionalProposalInfo additionalContext={data?.additional_context}/>
      }
    ];
    if (hasChangePermission) {
      defaultAccordianItems.push({
        label: 'Facts',
        key: 'facts',
        component: <ProposalFactsSection />
      });
    }
    return defaultAccordianItems;
  }, [proposalReferences, opportunityUid, data?.additional_context, hasChangePermission]);

  if (isLoading || data === undefined) {
    return <CenteredLoader />;
  }

  return (
    <>
      <Modal opened={opened} {...defaultModalProps}>
        <DeleteProposal
          close={close}
          proposalName={data?.name ?? ''}
          proposalUid={data?.uid ?? ''}
        />
      </Modal>
      <Modal opened={uploadOpened} {...defaultModalProps}>
        <UploadResources onClose={uploadHandlers.close}/>
      </Modal>
      <Box w="100vw" m={32} ta={'start'}>
        <LoadingOverlay visible={isLoading} />
        <Group ml={16} mr={16} mb={16} justify='space-between'>
          <Title order={5} fw={600} ta={'start'}>
            Proposal Management
          </Title>
          <Group >
            <Button
              onClick={handleExportProposal}
              disabled={!hasViewPermission || exportProposalMutation.isPending}
            >
              Export
            </Button>
            <Button
              disabled={!hasDeletePermission}
              onClick={open}
              variant='outline'
              color='red'
            >
              Delete Proposal
            </Button>
          </Group>
        </Group>
        <ScrollArea mt={8} pr={16} h='calc(100vh - 210px)'>
          <DetailsSection proposal={data} />
          <Accordion
            classNames={{ content: classes.content, chevron: classes.chevron }}
            chevron={<ArrowRight />}
            chevronPosition="left"
          >
            {accordionData.map((item) => (
              <Accordion.Item value={item.key} key={item.key}>
                <Accordion.Control>
                  <Title fw={500} order={6}>
                  {item.label}
                  </Title>
                </Accordion.Control>
                <Accordion.Panel>
                  {item.component}
                </Accordion.Panel>
              </Accordion.Item>
            ))}
          </Accordion>
        </ScrollArea>
      </Box>
    </>
  );
};

export default ManageProposalPage;
