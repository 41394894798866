import { useDispatch } from 'react-redux';
import { LoadingOverlay, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useCallback } from 'react';

import {
  updateText as updateOutlineText
} from '../../../redux/OutlineEditorSlice';
import ResponseTextEditor from './ResponseTextEditor';
import useEnsisMutation from '../../../hooks/useEnsisMutation';
import classes from './ResponseEditor.module.css';
import { type RequirementResponse } from '../../../types/apiTypes';
import { GenerateResponse } from '../../../components/Modals';
import { defaultModalProps } from '../../../utils/mantineUtils';
import { withAnalyticsEvent } from '../../../utils/analyticsUtils';

interface Props {
  proposalUid: string
  canEdit: boolean
  responseData?: RequirementResponse
  shouldShowWarning: boolean
  outlineOutputFormat: string
  setShouldShowWarningFalse: () => void
  onAcceptGeneratedText?: (generatedText: string) => void
};
const OutlineResponseEditor: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const { responseData, proposalUid, outlineOutputFormat, onAcceptGeneratedText } = props;
  const [generateModalOpened, generateModalHandlers] = useDisclosure();

  const onEditorUpdate = useCallback((newContent: string) => {
    dispatch(updateOutlineText({
      uid: responseData?.uid ?? '',
      content: newContent,
      originalContent: responseData?.content ?? undefined
    }));
  }, [responseData?.content, responseData?.uid]);

  const saveRequirementResponse = useEnsisMutation(
    '/app/requirement-responses',
    {
      contentType: 'application/json',
      requestType: 'patch',
      showSuccessMessage: false,
      queryKeysToInvalidate: [
        `/app/proposals/${proposalUid}/sections/${responseData?.proposal_section?.uid}/requirement-responses`
      ],
      onSuccess: (data) => {
        if (onAcceptGeneratedText !== undefined) {
          const generatedText = data[0].content;
          onAcceptGeneratedText(generatedText ?? '');
        }
      }
    }
  );

  const handleAcceptGenerateContent = useCallback((generatedText: string) => {
    saveRequirementResponse.mutate(
      {
        proposal_uid: proposalUid,
        edits: [{
          requirement_response_uid: responseData?.uid ?? '',
          new_content: generatedText
        }]
      }
    );
  }, [responseData?.uid, saveRequirementResponse.mutate]);

  return (
    <>
      <Modal opened={generateModalOpened} {...defaultModalProps}>
        <GenerateResponse
          onClose={generateModalHandlers.close}
          requirementResponseUid={responseData?.uid ?? ''}
          onAccept={handleAcceptGenerateContent}
          outlineOutputFormat={outlineOutputFormat}
        />
      </Modal>
      <LoadingOverlay visible={saveRequirementResponse.isPending} />
      <ResponseTextEditor
        {...props}
        requirementResponseUid={responseData?.uid ?? ''}
        initialContent={responseData?.content ?? ''}
        proposalSectionUid={responseData?.proposal_section?.uid ?? ''}
        onEditorUpdate={onEditorUpdate}
        contentStyleName={classes.content}
        onGenerateText={withAnalyticsEvent(generateModalHandlers.open, 'GenerateResponse')}
      />
    </>
  );
};

export default OutlineResponseEditor;
