import { useCallback } from 'react';

import useEnsisMutation from '../../../hooks/useEnsisMutation';
import AcceptChanges from '../AcceptChanges';
import { CenteredLoader } from '../..';
import SelectOutlineOutputFormat from '../SelectOutlineOutputFormat';

interface Props {
  onClose: () => void
  onAccept: (generatedText: string) => void
  requirementResponseUid: string
  outlineOutputFormat: string
}

const GenerateResponse: React.FC<Props> = (props: Props) => {
  const { onClose, onAccept, requirementResponseUid, outlineOutputFormat } = props;
  const generateRequirementResponse = useEnsisMutation(
    '/app/proposal-editing/generate-responses',
    {
      contentType: 'application/json',
      requestType: 'post',
      showSuccessMessage: false
    }
  );

  const handleGenerateResponse = useCallback(() => {
    generateRequirementResponse.mutate({
      requirement_response_uids: [requirementResponseUid]
    });
  }, [requirementResponseUid]);

  const centeredLoader = <CenteredLoader h={250} />;

  return (<>
    { generateRequirementResponse.isPending && centeredLoader }
    { generateRequirementResponse.isSuccess && (
      <AcceptChanges
        {...props}
        onPrimaryClick={onAccept}
        title='Review your generated content'
        onSecondaryClick={handleGenerateResponse}
        onClose={onClose}
        newContent={generateRequirementResponse.data.responses[requirementResponseUid]?.new_text}
        runId={generateRequirementResponse.data?.run_id}
      />
    )}
    {generateRequirementResponse.isIdle && (
      <SelectOutlineOutputFormat
        onClose={onClose}
        onClickNext={handleGenerateResponse}
        defaultOutlineOutputFormat={outlineOutputFormat}
      />)
    }
  </>);
};

export default GenerateResponse;
