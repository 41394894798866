import { Button, Group, Title } from '@mantine/core';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { SectionTitleDropdown } from '../DropdownMenus';
import { ChevronRight } from '../../icons';
import { type SectionData } from '../../Pages/Editor/SectionEditor';

interface Props {
  sectionTitle: string
  sectionUid: string
  canEdit: boolean
  isOnlyTopLevelSection: boolean
  handleOpenAddRequirement: (sectionUid: string) => void
  handleOpenAddSubsection: (parentSectionUid: string) => void
  handleOpenDelete: (sectionUid: string) => void
  handleOpenRename: (sectionUid: string) => void
  parentSection?: SectionData
}

const SectionEditorTitle: React.FC<Props> = (props: Props) => {
  const { parentSection } = props;
  const { organization, proposalUid } = useParams();

  const hasParentSection = parentSection !== undefined;
  const navigate = useNavigate();

  const onNavigateToParent = useCallback(() => {
    navigate(`/org/${organization}/proposals/${proposalUid}/edit/${parentSection?.uid}`);
  }, [parentSection?.uid]);
  const parentSectionBreadcrumb = (
    <Group gap={4}>
      <Button variant="subtle" h={36} p='0px 8px' onClick={onNavigateToParent}>
        <Title order={5} fw={600}> {parentSection?.title} </Title>
      </Button>
      <ChevronRight/>
    </Group>
  );

  return (
    <Group gap={16}>
      {hasParentSection && parentSectionBreadcrumb}
      <SectionTitleDropdown isSubsection={hasParentSection} {...props}/>
    </Group>
  );
};

export default SectionEditorTitle;
