import { ScrollArea, Text } from '@mantine/core';
import { useCallback } from 'react';

import { ModalContent } from '../..';

import classes from './AcceptChanges.module.css';
import useEnsisMutation from '../../../hooks/useEnsisMutation';
import { formatTextForEditor } from '../../../utils/stringUtils';
import { withAnalyticsEvent } from '../../../utils/analyticsUtils';

interface Props {
  onSecondaryClick: () => void
  onPrimaryClick: (generatedText: string) => void
  onClose: () => void
  runId: string
  title: string
  newContent: string
  originalContent?: string
  modalType?: 'generate' | 'regenerate'
}

const AcceptChanges: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    newContent,
    originalContent,
    onSecondaryClick,
    runId,
    title,
    onPrimaryClick: onAccept,
    modalType = 'generate'
  } = props;

  const primaryButtonLabel = modalType === 'generate' ? 'Accept' : 'Revise';
  const secondaryButtonLabel = modalType === 'generate' ? 'Retry' : 'Back';

  const submitFeedback = useEnsisMutation(
    '/app/proposal-editing/submit-feedback',
    {
      showSuccessMessage: false,
      showFailureMessage: false
    }
  );

  const handleClose = useCallback(() => {
    submitFeedback.mutate({ run_id: runId, score: 0.0 });
    onClose();
  }, [runId, onClose]);

  const handleAccept = useCallback(() => {
    submitFeedback.mutate({ run_id: runId, score: 1.0 });
    onAccept(formatTextForEditor(newContent));
    onClose();
  }, [newContent, runId, onAccept, onClose]);

  if (newContent === '') {
    return (
      <ModalContent
        onClose={handleClose}
        title='Unable to generate response'
      >
        <Text>
          Import relevant past work and information about your organization into
          your Resources, and try again.
        </Text>
      </ModalContent>
    );
  }

  return (
  <ModalContent
    onClose={onClose}
    title={title}
    secondaryButton={{ label: secondaryButtonLabel, onClick: onSecondaryClick }}
    primaryButton={{ label: primaryButtonLabel, onClick: withAnalyticsEvent(handleAccept, 'AcceptResponse') }}
  >
  {(originalContent?.length ?? 0) > 0 && <>
    <Text fz={16}>
      Before
    </Text>
    <ScrollArea className={classes.textarea}>
    <Text>
      {originalContent}
    </Text>
    </ScrollArea>
    <Text fz={16} mt={16}>
      After
    </Text>
    </>}
    <ScrollArea className={classes.textarea}>
      <Text>
        {newContent}
      </Text>
    </ScrollArea>
  </ModalContent>
  );
};

export default AcceptChanges;
