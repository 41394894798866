import { Box, Text, Button, SegmentedControl, Stack, Group } from '@mantine/core';
import { useCallback } from 'react';

import classes from './BulkGenerateResponses.module.css';
import useEnsisMutation from '../../../hooks/useEnsisMutation';
import type { RequirementResponse } from '../../../types/apiTypes';

interface GeneratedResponseCardProps {
  content: string
  accepted: boolean
  onSelectAccepted: (accepted: boolean) => void
  requirementResponse: RequirementResponse
  onRegenerate: (newContent: string) => void
}

const GeneratedResponseCard: React.FC<GeneratedResponseCardProps> = ({
  content,
  accepted,
  onSelectAccepted,
  requirementResponse,
  onRegenerate
}: GeneratedResponseCardProps) => {
  const regenerateRequirementResponse = useEnsisMutation(
    '/app/proposal-editing/generate-responses',
    {
      contentType: 'application/json',
      requestType: 'post',
      showSuccessMessage: false,
      onSuccess: (data) => { onRegenerate(data.responses[requirementResponse.uid]?.new_text); }
    }
  );

  const handleClickRegenerate = useCallback(() => {
    regenerateRequirementResponse.mutate({
      requirement_response_uids: [requirementResponse.uid]
    });
  }, [requirementResponse.uid]);

  const contentEmpty = content === '';
  const contentText = contentEmpty
    ? (
        <Text span c='red'>
          Import relevant past work and information about your organization into
          your Resources, and try again.
        </Text>
      )
    : (
        content?.split('\n').map((contentLine) => <Text key={contentLine}>{contentLine}</Text>)
      );

  return (
    <Box
      style={{ borderRadius: 8, border: '1px solid var(--mantine-color-gray-1)' }}
      mb={16}
    >
      <Box pl={16} pr={16} pt={8} pb={8}>
        <Text>{requirementResponse.requirement?.text}</Text>
      </Box>
      <Stack
        p={16}
        style={{ backgroundColor: 'var(--mantine-color-lightPurple-0)' }}
      >
        <Text pr={10} pl={10}>{contentText}</Text>
        <Group justify='flex-end'>
          <Button
            variant='outline'
            onClick={handleClickRegenerate}
            disabled={contentEmpty}
            loading={regenerateRequirementResponse.isPending}
          >
            Regenerate
          </Button>
          <SegmentedControl
            disabled={contentEmpty}
            value={accepted ? 'accept' : 'skip'}
            onChange={(value: string) => { onSelectAccepted(value === 'accept'); }}
            data={[
              { label: 'Skip', value: 'skip' },
              { label: 'Accept', value: 'accept' }
            ]}
            classNames={{
              root: classes.segmentedControlRoot,
              indicator: classes.segmentedControlIndicator
            }}
            style={{ borderRadius: 4, backgroundColor: 'var(--mantine-color-lightPurple-1)' }}
          />
        </Group>
      </Stack>
    </Box>
  );
};

export default GeneratedResponseCard;
