import {
  Textarea,
  Text,
  ScrollArea,
  Checkbox,
  Anchor,
  Select,
  Modal,
  Group
} from '@mantine/core';
import { useCallback, useState } from 'react';

import { useDisclosure } from '@mantine/hooks';

import { useParams } from 'react-router-dom';

import useEnsisMutation from '../../../hooks/useEnsisMutation';
import AcceptChanges from '../AcceptChanges';
import { CenteredLoader, ModalContent } from '../..';
import { MAX_AI_HINT_CHARACTERS } from '../../../utils/stringUtils';
import { logAnalyticsEvent, withAnalyticsEvent } from '../../../utils/analyticsUtils';
import { defaultModalProps } from '../../../utils/mantineUtils';
import FactManagementHelp from './FactManagementHelp';
import useOrgPermissions from '../../../hooks/useOrgPermissions';

export type FactType = 'proposal' | 'organization';

type MODAL_STAGE = 'revise' | 'review';

interface Props {
  onClose: () => void
  selectedText: string
  onAccept: (generatedText: string) => void
  proposalSectionUid: string
  requirementResponseUid?: string
}

export const viewTypeToLabelMap: Record<FactType, string> = {
  proposal: 'In this proposal',
  organization: 'In all proposals'
};

const RegenerateText: React.FC<Props> = (props: Props) => {
  const { onClose, selectedText, proposalSectionUid, requirementResponseUid, onAccept } = props;

  const { organization, proposalUid } = useParams();
  const { userHasPermission } = useOrgPermissions();

  const [hintText, setHintText] = useState('');
  const [showError, setShowError] = useState(false);
  const [saveFactChecked, setSaveFactChecked] = useState(false);
  const [modalStage, setModalStage] = useState<MODAL_STAGE>('revise');
  const [selectedFactType, setSelectedFactType] = useState<FactType>('proposal');
  const [factManagementHelpOpened, factManagementHelpHandlers] = useDisclosure();

  const userHasOrgFactsPermission = userHasPermission('add_memory_items');
  const hasInputtedHint = hintText.length > 0;
  const shouldCreateFact = saveFactChecked && hasInputtedHint;

  const viewSelectorData = [{
    value: 'proposal',
    label: viewTypeToLabelMap.proposal
  }];

  if (userHasOrgFactsPermission) {
    viewSelectorData.push({
      value: 'organization',
      label: viewTypeToLabelMap.organization
    });
  }

  const getNewText = useEnsisMutation(
    '/app/proposal-editing/adjust-text/custom-hint',
    {
      contentType: 'application/json',
      requestType: 'post',
      showSuccessMessage: false,
      failureMessage: 'Something went wrong, please try again.'
    }
  );

  const createNewProposalFact = useEnsisMutation(
    `/app/proposals/${proposalUid}/memory-items`,
    {
      contentType: 'application/json',
      requestType: 'post',
      showSuccessMessage: false,
      failureMessage: 'Something went wrong, please try again.',
      queryKeysToInvalidate: [`/app/proposals/${proposalUid}/memory-items`]
    }
  );

  const createNewOrganizationFact = useEnsisMutation(
    `/app/organizations/${organization}/memory-items`,
    {
      contentType: 'application/json',
      requestType: 'post',
      showSuccessMessage: false,
      failureMessage: 'Something went wrong, please try again.',
      queryKeysToInvalidate: [`/app/organizations/${organization}/memory-items`]
    }
  );

  const createFact = () => {
    if (selectedFactType === 'proposal') {
      logAnalyticsEvent('CreateProposalFact');
      createNewProposalFact.mutate({
        items: [hintText]
      });
    } else {
      logAnalyticsEvent('CreateOrganizationFact');
      createNewOrganizationFact.mutate({
        items: [hintText]
      });
    }
  };

  const onSubmit = useCallback(() => {
    setModalStage('review');
    if (shouldCreateFact) {
      createFact();
    }
    logAnalyticsEvent('Regenerate');
    getNewText.mutate({
      text: selectedText,
      hint: hintText,
      proposal_section_uid: proposalSectionUid,
      requirement_response_uid: requirementResponseUid
    });
  }, [selectedText, hintText, selectedFactType, shouldCreateFact]);

  if (getNewText.isPending) {
    return <CenteredLoader h={250} />;
  }

  if (getNewText.isSuccess && modalStage === 'review') {
    return (
      <AcceptChanges
        title='Review your regenerated content'
        onClose={onClose}
        onSecondaryClick={() => { setModalStage('revise'); }}
        onPrimaryClick={withAnalyticsEvent(onAccept, 'AcceptRegenerate')}
        originalContent={selectedText}
        newContent={getNewText?.data.new_text}
        runId={getNewText?.data?.run_id}
        modalType='regenerate'
      />
    );
  }

  const handleOnChange = (newText: string) => {
    if (newText.length < MAX_AI_HINT_CHARACTERS) {
      setHintText(newText);
      setShowError(false);
    } else {
      setShowError(true);
    }
  };

  const whatsThisText = (
    <Anchor
      c='var(--mantine-color-gray-5)'
      onClick={() => { factManagementHelpHandlers.open(); }}
      style={{ textDecoration: 'underline' }}
    >
      {'What\'s this?'}
    </Anchor>
  );

  return (
    <ModalContent
      title='Tell Ensis what to change about the selected text'
      onClose={onClose}
      primaryButton={{ label: 'Next', onClick: onSubmit }}
      secondaryButton={{ label: 'Cancel', onClick: onClose }}
    >
      <Modal opened={factManagementHelpOpened} {...defaultModalProps} size={1000}>
        <FactManagementHelp onClose={() => { factManagementHelpHandlers.close(); }} />
      </Modal>
      <ScrollArea mb={24} h={100}>
        <Text>
          {selectedText}
        </Text>
      </ScrollArea>
      <Textarea
        value={hintText}
        onChange={(event) => { handleOnChange(event.target.value); }}
        placeholder='Example: The products we would like to highlight most in this
        proposal are our testing kits. Adjust this text accordingly.'
        autosize
        minRows={3}
        maxRows={4}
        withErrorStyles={false}
        error={showError && "You've reached the character limit"}
      />
      <Group
        m={0}
        mt={24}
        style={{
          padding: '8px 12px 8px 12px',
          borderRadius: 4,
          display: 'flex',
          backgroundColor: 'var(--mantine-color-greyPurple-0)'
        }}
      >
        <Checkbox
          style={{ alignSelf: 'center' }}
          radius={4}
          checked={saveFactChecked}
          disabled={!hasInputtedHint}
          onChange={(event) => { setSaveFactChecked(event.currentTarget.checked); }}
        />
        <Text>
          Store this fact for future responses. {whatsThisText}
        </Text>
        <Select
          comboboxProps={{ offset: 0 }}
          disabled={!hasInputtedHint}
          allowDeselect={false}
          value={selectedFactType}
          w={240}
          style={{
            alignSelf: 'flex-end',
            marginLeft: 'auto'
          }}
          styles={{
            input: {
              borderRadius: 4
            }
          }}
          scrollAreaProps={{ type: 'hover' }}
          data={viewSelectorData}
          onOptionSubmit={(value: string) => { setSelectedFactType(value as FactType); }}
        />
      </Group>
    </ModalContent>
  );
};

export default RegenerateText;
