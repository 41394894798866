import { useCallback, useEffect, useState } from 'react';
import { Box, Title, Text, ScrollArea, LoadingOverlay } from '@mantine/core';

import useEnsisMutation from '../../../hooks/useEnsisMutation';
import CenteredLoader from '../../CenteredLoader';
import ModalContent from '../../ModalContent';
import { formatTextForEditor } from '../../../utils/stringUtils';
import type { RequirementResponse } from '../../../types/apiTypes';
import GeneratedResponseCard from './GeneratedResponseCard';
import { logAnalyticsEvent } from '../../../utils/analyticsUtils';

const GenerateResponsesLoadingState: React.FC = () => (
  <Box ta='center' p={24}>
    <Title order={5} c='var(--mantine-color-darkPurple-5)'>Generating responses</Title>
    <Text c='var(--mantine-color-darkPurple-5)'>
      Please wait while we generate responses for unpopulated fields. This may take a minute...
    </Text>
    <CenteredLoader m={36} h={150} loaderProps={{ size: 150 }}/>
  </Box>
);

interface Props {
  onClose: () => void
  onSubmit: (generatedTexts: Record<string, string>) => Promise<void>
  requirementResponses: RequirementResponse[]
}
const BulkGenerateResponses: React.FC<Props> = (props: Props) => {
  const { onClose, onSubmit, requirementResponses } = props;
  const requirementResponseUids = requirementResponses.map((response) => response.uid);
  // Tracking which responses are regenerated to calculate feedback score on submission
  const [unmodifiedResponseUids, setUnmodifedResponseUids] = useState(new Set(requirementResponseUids));
  const [acceptedUids, setAcceptedUids] = useState(new Set());
  const [generatedResponses, setGeneratedResponses] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);

  const generateRequirementResponses = useEnsisMutation(
    '/app/proposal-editing/generate-responses',
    {
      contentType: 'application/json',
      requestType: 'post',
      showSuccessMessage: false,
      onSuccess: (data) => {
        const formattedEntries = Object.entries(data.responses)?.map(([uid, response]) => (
          [uid, response.new_text]
        ));
        const nonEmptyUids = formattedEntries
          .filter(([uid, response]) => response !== '')
          .map(([uid, response]) => uid);
        setAcceptedUids(new Set(nonEmptyUids));
        const formattedResponsesObj: Record<string, string> = Object.fromEntries(formattedEntries);
        setGeneratedResponses(formattedResponsesObj);
      }
    }
  );

  const submitFeedbackMutation = useEnsisMutation(
    '/app/proposal-editing/submit-feedback',
    {
      showSuccessMessage: false,
      showFailureMessage: false
    }
  );

  const runId = generateRequirementResponses.data?.run_id;
  const feedbackScore = acceptedUids.intersection(unmodifiedResponseUids).size /
    requirementResponseUids.length;

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const generatedTextsToSave = Object.fromEntries(
        Object.entries(generatedResponses).filter(
          ([uid, response]) => acceptedUids.has(uid)
        ).map(
          ([uid, response]) => {
            logAnalyticsEvent('AcceptResponse');
            return [uid, formatTextForEditor(response)];
          }
        )
      );
      await submitFeedbackMutation.mutateAsync({
        run_id: runId ?? '',
        score: feedbackScore
      });
      await onSubmit(generatedTextsToSave);
      onClose();
    } catch {
      setLoading(false);
    }
  }, [
    acceptedUids,
    generatedResponses,
    onSubmit,
    onClose,
    runId,
    feedbackScore
  ]);

  const handleGenerateResponses = useCallback(() => {
    generateRequirementResponses.mutate({
      requirement_response_uids: requirementResponseUids
    });
  }, [generateRequirementResponses.mutate, requirementResponseUids]);

  const handleAcceptAll = useCallback(() => {
    setAcceptedUids(new Set(requirementResponseUids.filter((uid) => generatedResponses[uid] !== '')));
  }, [requirementResponseUids]);

  const handleRegenerateResponse = useCallback((uid: string, newValue: string) => {
    setGeneratedResponses({
      ...generatedResponses,
      [uid]: newValue
    });
    const newUnmodifiedSet = new Set(unmodifiedResponseUids);
    newUnmodifiedSet.delete(uid);
    setUnmodifedResponseUids(newUnmodifiedSet);
  }, [generatedResponses, unmodifiedResponseUids]);

  const handleSelectAccepted = useCallback((uid: string, accepted: boolean) => {
    const updatedSet = new Set(acceptedUids);
    if (accepted) {
      updatedSet.add(uid);
    } else {
      updatedSet.delete(uid);
    }
    setAcceptedUids(updatedSet);
  }, [acceptedUids]);

  // Start generating initial responses on mount
  useEffect(() => {
    handleGenerateResponses();
  }, []);

  if (generateRequirementResponses.isPending) {
    return <GenerateResponsesLoadingState />;
  }

  const noneAccepted = acceptedUids.size === 0;

  return (
    <ModalContent
      onClose={onClose}
      title='Review your generated content'
      secondaryButton={{ label: 'Accept All', onClick: handleAcceptAll }}
      primaryButton={{
        label: 'Save',
        onClick: () => { void handleSubmit(); },
        disabled: noneAccepted
      }}
    >
      <LoadingOverlay visible={loading} />
      <ScrollArea.Autosize mah='450px'>
        {requirementResponses?.map((response) => (
          <GeneratedResponseCard
            key={response.uid}
            content={generatedResponses[response.uid]}
            onRegenerate={(newValue) => {
              handleRegenerateResponse(response.uid, newValue);
            }}
            accepted={acceptedUids.has(response.uid)}
            onSelectAccepted={(accepted) => {
              handleSelectAccepted(response.uid, accepted);
            }}
            requirementResponse={response}
          />
        ))}
      </ScrollArea.Autosize>
    </ModalContent>
  );
};

export default BulkGenerateResponses;
